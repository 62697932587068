import React from "react"
import { FaTwitter, FaInstagram } from "react-icons/fa"

export const mainMenuItems = [
  {
    path: "/",
    title: "home",
  },
  {
    path: "#about",
    title: "about",
  },
  {
    path: "#events",
    title: "events",
  },
  {
    path: "#contact",
    title: "contact",
  },
]

export const socialMenuItems = [
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/glimm.art",
    name: "LinkedIn",
  },
  {
    icon: <FaTwitter />,
    url: "https://www.twitter.com/ArtGlimm",
    name: "Twitter",
  },
]

export const footerMenuItems = [
  {
    path: "/privacy",
    title: "privacy",
  },
  {
    path: "/cookies",
    title: "cookies",
  },
]
