export const mainMenuItems = [
  {
    path: "#about",
    text: "about",
  },
  {
    path: "#events",
    text: "events",
  },
  {
    path: "#contact",
    text: "contact",
  },
]
